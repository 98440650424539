import { render, staticRenderFns } from "./GameOver.vue?vue&type=template&id=8a39472c&scoped=true&"
import script from "./GameOver.vue?vue&type=script&lang=js&"
export * from "./GameOver.vue?vue&type=script&lang=js&"
import style0 from "./GameOver.vue?vue&type=style&index=0&id=8a39472c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a39472c",
  null
  
)

export default component.exports